<template>
    <b-container fluid>
        <b-overlay :show="loading">
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                <b-row>
                                     <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Farmer" vid="far_general_info_id" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="nid"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('common_config.farmer_nid_no') }}
                                            </template>
                                                <b-form-input
                                                id="nid"
                                                v-model="formData.nid_no"
                                                :placeholder="$t('dae_grant_allocation_distribution.type_nid')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                     <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="mobile_no" vid="mobile_no">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="mobile_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.mobile_no')}}
                                            </template>
                                                <b-form-input
                                                id="mobile_no"
                                                v-model="formData.mobile_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <b-button v-if="onlyCreate" type="button" variant="primary"  @click="searchFarmer">{{ $t('globalTrans.search') }}</b-button>
                                    </b-col>
                                </b-row>
                                <template>
                                    <div class="col-sm-12 col-md-12" style="font-size:12px; background-color: #337982; padding:6px">
                                        <h5 class="text-white text-center">
                                            {{ $t('admission_form.gen_inf') }}
                                        </h5>
                                    </div>
                                </template>
                            <!-- =======================Demand Info Start======================= -->
                                <div class="row">
                                    <div class="col-sm-6 col-md-6 mt-2">
                                        <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="circular_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.circular')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.circular_id"
                                                :options="circularList"
                                                id="circular_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6 mt-2">
                                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="fiscal_year_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.fiscal_year')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.fiscal_year_id"
                                                :options="fiscalList"
                                                id="fiscal_year_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="season_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.season')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.season_id"
                                                :options="seasonList"
                                                id="season_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="project" vid="project_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="project_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.project')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.project_id"
                                                :options="projectNameList"
                                                id="project_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="subsidy_type" vid="subsidy_type">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="subsidy_type"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.subsidy_type')}}
                                            </template>
                                                <b-form-input
                                                id="subsidy_type"
                                                v-model="formData.subsidy_type_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                    <!-- =======================Demand Info End========================= -->
                    <!-- =======================Farmer General Info Start======================= -->
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="Name (En)" vid="name">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="name"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_name')}}
                                            </template>
                                                <b-form-input
                                                id="name"
                                                v-model="formData.name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="Farmer Father Name" vid="father_name">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="father_name"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_father_name_en')}}
                                            </template>
                                                <b-form-input
                                                id="father_name"
                                                v-model="formData.father_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="Mother Name" vid="mother_name">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="mother_name"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_mother_name_en')}}
                                            </template>
                                                <b-form-input
                                                id="season_name"
                                                v-model="formData.mother_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="date_of_birth" vid="date_of_birth">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="date_of_birth"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_date_of_birth')}}
                                            </template>
                                                <b-form-input
                                                id="date_of_birth"
                                                v-model="formData.date_of_birth"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="gender" vid="gender">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="gender"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_gender')}}
                                            </template>
                                                <b-form-input
                                                id="gender"
                                                v-model="formData.gender"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="Blood Group" vid="blood_group_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="blood_group_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('common_config.farmer_blood_group')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.blood_group_id"
                                                :options="bloodGroupList"
                                                id="fiscal_year_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="mobile_no" vid="mobile_no">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="mobile_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.mobile_no')}}
                                            </template>
                                                <b-form-input
                                                id="mobile_no"
                                                v-model="formData.mobile_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <!-- =======================Farmer General Info End========================= -->
                                    <template>
                                         <div style="font-size:12px; background-color: #337982; padding:6px">
                                            <h5 class="text-white text-center">
                                                {{ $t('dae_grant_allocation_distribution.frmr_pre') }}
                                            </h5>
                                        </div>
                                    </template>
                                <!-- =======================Farmer Permanent Address Start========================= -->
                                 <div class="row">
                                    <div class="col-sm-6 col-md-6 mt-2">
                                        <ValidationProvider name="division" vid="division">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="division"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.division')}}
                                            </template>
                                                <b-form-input
                                                id="division_name"
                                                v-model="formData.division_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6 mt-2">
                                        <ValidationProvider name="District" vid="district">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="district"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.district')}}
                                            </template>
                                                <b-form-input
                                                id="season_name"
                                                v-model="formData.district_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="upazilla" vid="upazila">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="upazila"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.upazila')}}
                                            </template>
                                                <b-form-input
                                                id="season_name"
                                                v-model="formData.upazilla_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="union" vid="union">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="union"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.union')}}
                                            </template>
                                                <b-form-input
                                                id="union"
                                                v-model="formData.union_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="ward_no" vid="ward_no">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="ward_no"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.ward_no')}}
                                            </template>
                                                <b-form-input
                                                id="ward_no"
                                                v-model="formData.ward_no"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="Village" vid="village">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="village"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.village')}}
                                            </template>
                                                <b-form-input
                                                id="village"
                                                v-model="formData.village"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <ValidationProvider name="block" vid="block">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="block"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('dae_grant_allocation_distribution.block')}}
                                            </template>
                                                <b-form-input
                                                id="block"
                                                v-model="formData.block"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                readonly
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            <!-- =======================Farmer Present Address End=========================== -->
                                <template>
                                        <div style="font-size:12px; background-color: #337982; padding:6px">
                                            <h5 class="text-white text-center">
                                            {{ $t('dae_grant_allocation_distribution.frmr_per') }}
                                        </h5>
                                    </div>
                                </template>
                            <!-- =======================Farmer Permanent Address Start========================= -->
                            <div class="row">
                                <div class="col-sm-6 col-md-6 mt-2">
                                    <ValidationProvider name="division" vid="division">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="division"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.division')}}
                                        </template>
                                            <b-form-input
                                            id="division_name"
                                            v-model="formData.per_division_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-sm-6 col-md-6 mt-2">
                                    <ValidationProvider name="district" vid="district">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="district"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.district')}}
                                        </template>
                                            <b-form-input
                                            id="district"
                                            v-model="formData.per_district_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <ValidationProvider name="upazila" vid="upazila">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="upazila"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.upazila')}}
                                        </template>
                                            <b-form-input
                                            id="upazila"
                                            v-model="formData.per_upazilla_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <ValidationProvider name="union" vid="union">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="union"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.union')}}
                                        </template>
                                            <b-form-input
                                            id="union"
                                            v-model="formData.per_union_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <ValidationProvider name="ward_no" vid="ward_no">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="ward_no"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.ward_no')}}
                                        </template>
                                            <b-form-input
                                            id="ward_no"
                                            v-model="formData.per_ward_no"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <ValidationProvider name="village" vid="village">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="village"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('globalTrans.village')}}
                                        </template>
                                            <b-form-input
                                            id="village"
                                            v-model="formData.per_village"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <ValidationProvider name="Block" vid="block">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="block"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('dae_grant_allocation_distribution.block')}}
                                        </template>
                                            <b-form-input
                                            id="block"
                                            v-model="formData.per_block"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-9"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </div>
                            </b-form>
                        </ValidationObserver>
                    </template>
                </iq-card>
            </b-col>
        </b-overlay>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyDemandCollectionStore, subsidyDemandCollectionUpdate, farmerDataApi, circularDataApi } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            this.onlyCreate = false
            const tmp = this.getformDataData()
            this.formData.circular_id = tmp.circular_id
            this.formData.nid_no = tmp.nid_no
            this.formData.land_quantity = tmp.land_quantity
            this.getCircularDetails(tmp.circular_id)
            this.searchFarmer()
            this.formData.crop_id = tmp.demand_collection_details.map(item => {
                return item.crop_id
            })
        }
    },
    data () {
        return {
            onlyCreate: true,
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            formData: {
                nid_no: '',
                mobile_no: '',
                fiscal_year_id: 0,
                circular_id: 0,
                season_id: 0,
                subsidy_type_id: 0,
                project_id: 0,
                far_general_info_id: 0,
                name: '',
                mother_name: '',
                father_name: '',
                date_of_birth: '',
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                union_id: 0,
                subsidy_type_name: '',
                division_name: '',
                district_name: '',
                upazilla_name: '',
                union_name: '',
                per_division_id: 0,
                per_district_id: 0,
                per_upazila_id: 0,
                per_union_id: 0,
                per_division_name: '',
                per_district_name: '',
                per_upazilla_name: '',
                per_union_name: ''
            },
            subsidyNameList: []
        }
    },
    watch: {
        'formData.partial_damage_converts_complete_loss': function (newVal, oldVal) {
            var tdLand = 0
            tdLand = parseFloat(this.formData.partial_damage_converts_complete_loss ? this.formData.partial_damage_converts_complete_loss : 0) + parseFloat(this.formData.damaged_land ? this.formData.damaged_land : 0)
            this.formData.total_damage_land = tdLand
        },
       'formData.total_land': function (newVal, oldVal) {
            var tLand = parseFloat(this.formData.total_land ? this.formData.total_land : 0)
            var affLand = parseFloat(this.formData.affected_land ? this.formData.affected_land : 0)
            if (tLand && tLand > 0) {
              this.formData.loss_rate_on_total_crop_land = (100 * affLand) / tLand
            } else {
            this.formData.loss_rate_on_total_crop_land = 0
            }
        },
       'formData.affected_land': function (newVal, oldVal) {
            var affLand = parseFloat(this.formData.affected_land ? this.formData.affected_land : 0)
            var pDamage = parseFloat(this.formData.partially_damaged_land ? this.formData.partially_damaged_land : 0)
            if (affLand && affLand > 0) {
              this.formData.partial_loss_rate = (100 * pDamage) / affLand
            } else {
            this.formData.partial_loss_rate = 0
            }
            // -----------LossRateOnTotalLand------
            var tLand = parseFloat(this.formData.total_land ? this.formData.total_land : 0)
            if (tLand && tLand > 0) {
              this.formData.loss_rate_on_total_crop_land = (100 * affLand) / tLand
            } else {
            this.formData.loss_rate_on_total_crop_land = 0
            }
        },
        'formData.partially_damaged_land': function (newVal, oldVal) {
            var affLand = parseFloat(this.formData.affected_land ? this.formData.affected_land : 0)
            var pDamage = parseFloat(this.formData.partially_damaged_land ? this.formData.partially_damaged_land : 0)
            if (affLand && affLand > 0) {
              this.formData.partial_loss_rate = (100 * pDamage) / affLand
            } else {
            this.formData.partial_loss_rate = 0
            }
        },
        // 'formData.nid_no': function (newVal, oldVal) {
        //     this.getFarmerInformation(newVal)
        // },
        'formData.circular_id': function (newValue) {
        this.getCircularDetails(newValue)
        }
    },
    mounted () {
        core.index()
    },
    computed: {
        bloodGroupList: function () {
            const list = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'O-' : 'ও−' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'A-' : 'এ−' },
            { value: 4, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
            { value: 5, text: this.$i18n.locale === 'en' ? 'B-' : 'বি−' },
            { value: 6, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
            { value: 7, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি−' },
            { value: 8, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' }
            ]
           return list
        },
        fiscalList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        seasonList: function () {
            return this.$store.state.incentiveGrant.commonObj.seasonList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        circularList: function () {
            const circularData = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.circular_type !== 1 && item.circular_type !== 4)
            return circularData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
         projectNameList: function () {
            const projectNameData = this.$store.state.incentiveGrant.commonObj.projectList.filter(item => item.status === 1)
            return projectNameData.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text_en }
                }
            })
        },
        cropList: function () {
            const cropData = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
            return cropData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    methods: {
        async getCircularDetails (circularId) {
            this.loading = true
            const params = Object.assign({}, { circular_id: circularId })
            await RestApi.getData(incentiveGrantServiceBaseUrl, circularDataApi, params).then(response => {
                if (response.success) {
                    this.formData.fiscal_year_id = response.data.fiscal_year_id
                    this.formData.season_id = response.data.season_id
                    this.formData.project_id = response.data.project_id
                    this.formData.subsidy_type = response.data.circular_type
                    if (response.data.circular_type === 1) {
                        this.formData.subsidy_type_name = this.$i18n.locale === 'bn' ? 'ভর্তুকি' : 'Subsidy'
                    } else if (response.data.circular_type === 2) {
                        this.formData.subsidy_type_name = this.$i18n.locale === 'bn' ? 'প্রণোদনা' : 'Incentive'
                    } else if (response.data.circular_type === 3) {
                        this.formData.subsidy_type_name = this.$i18n.locale === 'bn' ? 'পুনর্বাসন' : 'Rehabilitation'
                    }
                }
                this.loading = false
            })
        },
        async searchFarmer () {
            this.loading = true
            const params = { nid_no: this.formData.nid_no, mobile_no: this.formData.mobile_no, upazilla_id: this.$store.state.Auth.authUser.office_detail.upazilla_id }
            await RestApi.getData(incentiveGrantServiceBaseUrl, farmerDataApi, params).then(response => {
                if (response.success) {
                    this.formData.far_general_info_id = response.data.id
                    this.formData.date_of_birth = response.data.date_of_birth
                    this.formData.blood_group_id = response.data.blood_group_id
                    this.formData.father_name = this.$i18n.locale === 'bn' ? response.data.father_name_bn : response.data.father_name
                    this.formData.name = this.$i18n.locale === 'bn' ? response.data.name_bn : response.data.name
                    this.formData.mother_name = this.$i18n.locale === 'bn' ? response.data.mother_name_bn : response.data.mother_name
                    this.formData.gender = response.data.gender_id === '1' ? 'male' : 'Female'
                    this.formData.nid_no = response.data.nid_no
                    this.formData.mobile_no = response.data.mobile_no
                    // --------Present Address Obj Start-------
                    const divisionObject = this.$store.state.commonObj.divisionList.find(division => division.value === response.data.division_id)
                    this.formData.division_id = response.data.division_id
                    this.formData.division_name = divisionObject !== undefined ? this.$i18n.locale === 'bn' ? divisionObject.text_bn : divisionObject.text_en : ''
                    const districtObject = this.$store.state.commonObj.districtList.find(district => district.value === response.data.district_id)
                    this.formData.district_id = response.data.district_id
                    this.formData.district_name = districtObject !== undefined ? this.$i18n.locale === 'bn' ? districtObject.text_bn : districtObject.text_en : ''
                    const upazillaObject = this.$store.state.commonObj.upazilaList.find(upazillaItem => upazillaItem.value === response.data.upazilla_id)
                    this.formData.upazilla_id = response.data.upazilla_id
                    this.formData.upazilla_name = upazillaObject !== undefined ? this.$i18n.locale === 'bn' ? upazillaObject.text_bn : upazillaObject.text_en : ''
                    const unionObject = this.$store.state.commonObj.unionList.find(unionItem => unionItem.value === response.data.union_id)
                    this.formData.union_id = response.data.union_id
                    this.formData.union_name = unionObject !== undefined ? this.$i18n.locale === 'bn' ? unionObject.text_bn : unionObject.text_en : ''
                    const wardObject = this.$store.state.commonObj.wardList.find(wardItem => wardItem.value === response.data.ward_id)
                    this.formData.ward_no = wardObject !== undefined ? this.$i18n.locale === 'bn' ? wardObject.text_bn : wardObject.text_en : ''
                    this.formData.village = this.$i18n.locale === 'bn' ? response.data.village_bn : response.data.village_en
                    this.formData.block = this.$i18n.locale === 'bn' ? response.data.block_bn : response.data.block_en
                    // --------Present Address Obj End-------
                    // ----------PermanenetAddressObjStart------
                    const perDivisionObject = this.$store.state.commonObj.divisionList.find(preDivision => preDivision.value === response.data.per_division_id)
                    this.formData.per_division_id = response.data.per_division_id
                    this.formData.per_division_name = perDivisionObject !== undefined ? this.$i18n.locale === 'bn' ? perDivisionObject.text_bn : divisionObject.text_en : ''
                    const perDistrictObject = this.$store.state.commonObj.districtList.find(perDistrict => perDistrict.value === response.data.per_district_id)
                    this.formData.per_district_id = response.data.per_district_id
                    this.formData.per_district_name = perDistrictObject !== undefined ? this.$i18n.locale === 'bn' ? perDistrictObject.text_bn : perDistrictObject.text_en : ''
                    const perUpazillaObject = this.$store.state.commonObj.upazilaList.find(perUpazillaItem => perUpazillaItem.value === response.data.per_upazilla_id)
                    this.formData.per_upazila_id = response.data.per_upazilla_id
                    this.formData.per_upazilla_name = perUpazillaObject !== undefined ? this.$i18n.locale === 'bn' ? perUpazillaObject.text_bn : perUpazillaObject.text_en : ''
                    const perUnionObject = this.$store.state.commonObj.unionList.find(perUnionItem => perUnionItem.value === response.data.per_union_id)
                    this.formData.per_union_id = response.data.per_union_id
                    this.formData.per_union_name = perUnionObject !== undefined ? this.$i18n.locale === 'bn' ? perUnionObject.text_bn : perUnionObject.text_en : ''
                    const perWardObject = this.$store.state.commonObj.wardList.find(perWardItem => perWardItem.value === response.data.per_ward_id)
                    this.formData.per_ward_no = perWardObject !== undefined ? this.$i18n.locale === 'bn' ? perWardObject.text_bn : perWardObject.text_en : ''

                    this.formData.per_village = this.$i18n.locale === 'bn' ? response.data.per_village_bn : response.data.per_village_en
                    this.formData.per_block = this.$i18n.locale === 'bn' ? response.data.per_block_bn : response.data.per_block_en
                // ----------PermanenetAddressObjEnd------
                } else {
                     window.vm.$toast.error({
                        title: 'Error',
                        message: response.message
                    })
                }
                this.loading = false
            })
        },
        // async getFarmerInformation (nid) {
        //     this.loading = true
        //     const params = Object.assign({}, { nid: nid, upazilla_id: this.$store.state.Auth.authUser.office_detail.upazilla_id })
        //     await RestApi.getData(incentiveGrantServiceBaseUrl, farmerDataApi, params).then(response => {
        //         if (response.success) {
        //             this.formData.far_general_info_id = response.data.id
        //             this.formData.date_of_birth = response.data.date_of_birth
        //             this.formData.blood_group_id = response.data.blood_group_id
        //             this.formData.father_name = this.$i18n.locale === 'bn' ? response.data.father_name_bn : response.data.father_name
        //             this.formData.name = this.$i18n.locale === 'bn' ? response.data.name_bn : response.data.name
        //             this.formData.mother_name = this.$i18n.locale === 'bn' ? response.data.mother_name_bn : response.data.mother_name
        //             this.formData.gender = response.data.gender_id === '1' ? 'male' : 'Female'
        //             this.formData.mobile_no = response.data.mobile_no
        //             // --------Present Address Obj Start-------
        //             const divisionObject = this.$store.state.commonObj.divisionList.find(division => division.value === response.data.division_id)
        //             this.formData.division_id = response.data.division_id
        //             this.formData.division_name = divisionObject !== undefined ? this.$i18n.locale === 'bn' ? divisionObject.text_bn : divisionObject.text_en : ''
        //             const districtObject = this.$store.state.commonObj.districtList.find(district => district.value === response.data.district_id)
        //             this.formData.district_id = response.data.district_id
        //             this.formData.district_name = districtObject !== undefined ? this.$i18n.locale === 'bn' ? districtObject.text_bn : districtObject.text_en : ''
        //             const upazillaObject = this.$store.state.commonObj.upazilaList.find(upazillaItem => upazillaItem.value === response.data.upazilla_id)
        //             this.formData.upazilla_id = response.data.upazilla_id
        //             this.formData.upazilla_name = upazillaObject !== undefined ? this.$i18n.locale === 'bn' ? upazillaObject.text_bn : upazillaObject.text_en : ''
        //             const unionObject = this.$store.state.commonObj.unionList.find(unionItem => unionItem.value === response.data.union_id)
        //             this.formData.union_id = response.data.union_id
        //             this.formData.union_name = unionObject !== undefined ? this.$i18n.locale === 'bn' ? unionObject.text_bn : unionObject.text_en : ''
        //             const wardObject = this.$store.state.commonObj.wardList.find(wardItem => wardItem.value === response.data.ward_id)
        //             this.formData.ward_no = wardObject !== undefined ? this.$i18n.locale === 'bn' ? wardObject.text_bn : wardObject.text_en : ''
        //             // --------Present Address Obj End-------
        //             // ----------PermanenetAddressObjStart------
        //             const perDivisionObject = this.$store.state.commonObj.divisionList.find(preDivision => preDivision.value === response.data.per_division_id)
        //             this.formData.per_division_id = response.data.per_division_id
        //             this.formData.per_division_name = perDivisionObject !== undefined ? this.$i18n.locale === 'bn' ? perDivisionObject.text_bn : divisionObject.text_en : ''
        //             const perDistrictObject = this.$store.state.commonObj.districtList.find(perDistrict => perDistrict.value === response.data.per_district_id)
        //             this.formData.per_district_id = response.data.per_district_id
        //             this.formData.per_district_name = perDistrictObject !== undefined ? this.$i18n.locale === 'bn' ? perDistrictObject.text_bn : perDistrictObject.text_en : ''
        //             const perUpazillaObject = this.$store.state.commonObj.upazilaList.find(perUpazillaItem => perUpazillaItem.value === response.data.per_upazilla_id)
        //             this.formData.per_upazila_id = response.data.per_upazilla_id
        //             this.formData.per_upazilla_name = perUpazillaObject !== undefined ? this.$i18n.locale === 'bn' ? perUpazillaObject.text_bn : perUpazillaObject.text_en : ''
        //             const perUnionObject = this.$store.state.commonObj.unionList.find(perUnionItem => perUnionItem.value === response.data.per_union_id)
        //             this.formData.per_union_id = response.data.per_union_id
        //             this.formData.per_union_name = perUnionObject !== undefined ? this.$i18n.locale === 'bn' ? perUnionObject.text_bn : perUnionObject.text_en : ''
        //             const perWardObject = this.$store.state.commonObj.wardList.find(perWardItem => perWardItem.value === response.data.per_ward_id)
        //             this.formData.per_ward_no = perWardObject !== undefined ? this.$i18n.locale === 'bn' ? perWardObject.text_bn : perWardObject.text_en : ''
        //         // ----------PermanenetAddressObjEnd------
        //         } else {
        //              window.vm.$toast.error({
        //                 title: 'Error',
        //                 message: response.message
        //             })
        //         }
        //         this.loading = false
        //     })
        // },
        getformDataData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${subsidyDemandCollectionUpdate}/${this.id}`, this.formData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, subsidyDemandCollectionStore, this.formData)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        getSubsidyNameBySubsidyType (typeId) {
      const dataObject = this.$store.state.incentiveGrant.commonObj.subsidyList.filter(item => item.status === 1 && item.subsidy_type_id === typeId)
      return dataObject.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    }
    }
}
</script>
